import { useMoneyFormatter } from '@utils/numberUtils';
import _ from 'lodash';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Content, StyleDictionary, Table, TableCell, TDocumentDefinitions } from 'pdfmake/interfaces';
import { useIntl } from 'react-intl';
import { useOperatingCostFormatter } from './components/Charges/utils';
import { calculateAdditionalRent, calculateAnnualBaseRent, calculateAnnualOperatingCost, calculateAnnualRent, calculateRentableSquareFootage, CommercialConstructionCalculatorData, costCalculationMethodMessages, CostEstimate, EstimatesData, MortgageCalculatorScenario, OperatingCost, RentalRevenue } from './types';


const fonts = {
  // download default Roboto font from cdnjs.com
  Roboto: {
    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
  },
};

type TableRow = TableCell[];

const getCalculatedData = ({ mortgageCalculator, operatingCosts, revenues }: CommercialConstructionCalculatorData) => {
  const totalOperatingCosts = _.sumBy(operatingCosts, p => p.annualCost);

  const commonAreaSquareFootage = revenues.totalUsableSquareFootage / (1 - revenues.commonAreaPercentage) - revenues.totalUsableSquareFootage;
  const totalRentableSquareFootage = revenues.totalUsableSquareFootage + commonAreaSquareFootage;

  const potentialRevenue = _.sumBy(revenues.rentalRevenues, p => calculateAnnualBaseRent(p, revenues.commonAreaPercentage)) + _.sumBy(revenues.additionalRevenue, p => p.annualCost);
  const annualPayment = mortgageCalculator.scenarios[revenues.selectedMortgageScenario].annualPayment;
  const targetRevenue = annualPayment + annualPayment * revenues.targetRevenuePercent;
  const targetAnnualCostPerSquareFoot = Math.round(targetRevenue / totalRentableSquareFootage * 100) / 100;

  const projectedNetRevenue = (1 - revenues.vacancy) * potentialRevenue;

  const commonAreaFactor = revenues.commonAreaPercentage;
  const totalRevenues = (1 - revenues.vacancy) * ((_.sumBy(revenues.rentalRevenues, p => calculateAnnualRent(p, commonAreaFactor, _.sumBy(operatingCosts, p => p.annualCost), totalRentableSquareFootage)) + _.sumBy(revenues.additionalRevenue, p => p.annualCost)));

  const revenueDifferenceWithTarget = projectedNetRevenue - targetRevenue;

  return {
    totalOperatingCosts,
    commonAreaSquareFootage,
    totalRentableSquareFootage,
    potentialRevenue,
    annualPayment,
    targetRevenue,
    totalRevenues,
    revenueDifferenceWithTarget,
    targetAnnualCostPerSquareFoot,
    projectedNetRevenue
  };
};

export const useCommercialConstructionPDFGenerator = () => {
  const { formatMessage } = useIntl();
  const formatMoney = useMoneyFormatter();
  const { formatCost } = useOperatingCostFormatter();

  const roundToTwoDecimals = (value: number) => Math.round(value * 100) / 100;
  const formatPercent = (value?: number) => value ? `${roundToTwoDecimals(value * 100)}%` : '0%';

  const styles: StyleDictionary = {
    header: {
      fontSize: 18,
      bold: true,
      marginBottom: 10,
      marginTop: 10
    },
    subheader: {
      fontSize: 14,
      bold: true,
      marginTop: 8,
      marginBottom: 4
    },
    tableExample: {
      margin: [0, 5, 0, 15]
    },
    tableHeader: {
      bold: true,
      fontSize: 13,
      color: 'black'
    }
  };

  const estimatesSection = (data: EstimatesData): Content => {
    const estimateToRows = (estimate: CostEstimate): TableRow[] => {
      if (estimate.childCosts) {
        return [
          [estimate.name, formatMoney(estimate.estimate)],
          ...estimate.childCosts.map(cost => [{
            text: `-    ${cost.name}`,
          }, {
            text: formatMoney(cost.estimate),
          }])
        ];
      }

      return [[estimate.name, formatMoney(estimate.estimate)]];
    };

    return [
      { text: formatMessage({ id: 'Estimated project costs' }), style: 'header' },
      {
        table: {
          widths: ['*', '*'],
          body: [
            [formatMessage({ id: 'Name' }), formatMessage({ id: 'Estimate' })].map(text => ({ text, style: 'tableHeader' })),
            ...data.costEstimates.flatMap(estimateToRows)
          ]
        }
      },
      {
        text: `${formatMessage({ id: 'Total estimate' })}: ${formatMoney(data.totalEstimate)}`,
        style: 'tableHeader',
        marginTop: 16
      },
    ];
  };

  const mortgageSection = (data: CommercialConstructionCalculatorData): Content => {
    const totalEstimate = data.calculatorValues.totalEstimate;

    const scenarioToTable = (scenario: MortgageCalculatorScenario): Table => {
      return {
        widths: ['*', '*'],
        body: [
          [{}, { text: formatMessage({ id: 'Mortgage information' }), style: 'tableHeader' }],
          [{ text: formatMessage({ id: 'Down payment percent' }), style: 'tableHeader' }, formatPercent(scenario.downPaymentPercent)],
          [{ text: formatMessage({ id: 'Down payment amount' }), style: 'tableHeader' }, formatMoney(scenario.downPaymentPercent * totalEstimate)],
          [{ text: formatMessage({ id: 'Loan amount' }), style: 'tableHeader' }, formatMoney(scenario.loanAmount)],
          [{ text: formatMessage({ id: 'Interest rate' }), style: 'tableHeader' }, formatPercent(scenario.annualInterestRate)],
          [{ text: formatMessage({ id: 'Amortization period' }), style: 'tableHeader' }, scenario.amortizationPeriod],

          [{}, { text: formatMessage({ id: 'Payment' }), style: 'tableHeader' }],
          [{ text: formatMessage({ id: 'Monthly rate' }), style: 'tableHeader' }, formatPercent(scenario.monthlyInterestRate / 100)],
          [{ text: formatMessage({ id: 'Monthly payment' }), style: 'tableHeader' }, formatMoney(scenario.monthlyPayment)],

          [{}, { text: formatMessage({ id: 'Totals' }), style: 'tableHeader' }],
          [{ text: formatMessage({ id: 'Number of payments' }), style: 'tableHeader' }, scenario.amortizationPeriod * 12],
          [{ text: formatMessage({ id: 'Total payment' }), style: 'tableHeader' }, formatMoney(scenario.totalPayment)],
          [{ text: formatMessage({ id: 'Total interest' }), style: 'tableHeader' }, formatMoney(scenario.totalInterestsPayment)]
        ]
      };
    };

    const selectedScenario = data.mortgageCalculator.scenarios[data.revenues.selectedMortgageScenario];

    return [
      { text: formatMessage({ id: 'Mortgage scenario' }), style: 'header', pageBreak: 'before' },
      { table: scenarioToTable(selectedScenario) }
    ];
  };

  const costsSection = (data: CommercialConstructionCalculatorData): Content => {
    const costs = data.operatingCosts;

    const {
      totalOperatingCosts,
      totalRentableSquareFootage,
      potentialRevenue,
    } = getCalculatedData(data);

    const purchasePrice = data.calculatorValues.totalEstimate;

    const costToRow = (cost: OperatingCost): TableRow => {
      return [
        { text: cost.name, style: 'tableHeader' },
        { text: formatMessage(costCalculationMethodMessages[cost.calculationMethod]) },
        { text: formatCost(cost) },
        { text: formatMoney(calculateAnnualOperatingCost(cost, purchasePrice, potentialRevenue, totalRentableSquareFootage)) }
      ];
    };

    return [
      { text: formatMessage({ id: 'Operating costs' }), style: 'header', pageBreak: 'before' },
      {
        table: {
          widths: ['*', 'auto', 'auto', 'auto'],
          body: [
            [
              formatMessage({ id: 'Name' }),
              formatMessage({ id: 'Calculation method' }),
              formatMessage({ id: 'Cost' }),
              formatMessage({ id: 'Annual cost' })
            ].map(text => ({ text, style: 'tableHeader' })),
            ...costs.map(costToRow)
          ]
        }
      },
      {
        text: `${formatMessage({ id: 'Total operating costs' })}: ${formatMoney(totalOperatingCosts)}`,
        style: 'tableHeader',
        marginTop: 16,
      }
    ];
  };

  const revenuesSection = (data: CommercialConstructionCalculatorData): Content => {
    const { revenues } = data;
    const {
      totalOperatingCosts,
      totalRentableSquareFootage,
      potentialRevenue,
      annualPayment,
      targetRevenue,
      projectedNetRevenue,
      revenueDifferenceWithTarget,
      targetAnnualCostPerSquareFoot
    } = getCalculatedData(data);

    const rentalRevenueToRow = (revenue: RentalRevenue): TableRow => {
      const additionalRent = calculateAdditionalRent(revenue, revenues.totalUsableSquareFootage, totalOperatingCosts);
      const rentableSquareFootage = calculateRentableSquareFootage(revenue, revenues.commonAreaPercentage);
      const annualRent = calculateAnnualRent(revenue, revenues.commonAreaPercentage, totalOperatingCosts, revenues.totalUsableSquareFootage);
      return [
        revenue.name,
        roundToTwoDecimals(revenue.usableSquareFootage),
        roundToTwoDecimals(revenue.usableSquareFootage / (1 - revenues.commonAreaPercentage)),
        formatMoney(revenue.annualCostPerSquareFootage),
        formatMoney(rentableSquareFootage * targetAnnualCostPerSquareFoot),
        formatMoney(additionalRent / rentableSquareFootage),
        formatMoney(additionalRent),
        formatMoney(annualRent),
        formatMoney(annualRent / rentableSquareFootage)
      ];
    };

    return [
      { text: formatMessage({ id: 'Revenues' }), style: 'header', pageBreak: 'before', pageOrientation: 'landscape' },
      {
        table: {
          widths: ['*', '*'],
          body: [
            [formatMessage({ id: 'Annual cost' }), formatMoney(annualPayment)],
            [formatMessage({ id: 'Target revenue %' }), formatPercent(revenues.targetRevenuePercent)],
            [formatMessage({ id: 'Target revenue' }), formatMoney(targetRevenue)],
            [formatMessage({ id: 'Total usable square footage' }), roundToTwoDecimals(revenues.totalUsableSquareFootage)],
            [formatMessage({ id: 'Common area percentage' }), formatPercent(revenues.commonAreaPercentage)],
            [formatMessage({ id: 'Estimated total rentable space' }), roundToTwoDecimals(totalRentableSquareFootage)],
            [formatMessage({ id: 'Target rent per square foot' }), formatMoney(targetAnnualCostPerSquareFoot)]
          ]
        }
      },

      { text: formatMessage({ id: 'Rental revenues' }), style: 'subheader' },
      {
        table: {
          body: [
            [
              formatMessage({ id: 'Name' }),
              formatMessage({ id: 'Usable square footage' }),
              formatMessage({ id: 'Rentable square footage' }),
              formatMessage({ id: 'Base rent per sq. ft.' }),
              formatMessage({ id: 'Base annual rent' }),
              formatMessage({ id: 'Additional rent per sq. ft.' }),
              formatMessage({ id: 'Additional rent' }),
              formatMessage({ id: 'Annual rent' }),
              formatMessage({ id: 'Annual rent per sq. ft.' }),
            ].map(text => ({ text, style: 'tableHeader' })),
            ...data.revenues.rentalRevenues.map(rentalRevenueToRow)
          ]
        }
      },

      { text: formatMessage({ id: 'Additional revenues' }), style: 'subheader' },
      {
        table: {
          widths: ['*', '*'],
          body: [
            [
              formatMessage({ id: 'Name' }),
              formatMessage({ id: 'Annual revenue' }),
            ].map(text => ({ text, style: 'tableHeader' })),
            ...revenues.additionalRevenue.map(p => [p.name, formatMoney(p.annualCost)])
          ]
        }
      },

      { text: formatMessage({ id: 'Revenue summary' }), style: 'subheader' },
      {
        table: {
          widths: ['*', '*'],
          body: [
            [formatMessage({ id: 'Total potential revenues' }), formatMoney(potentialRevenue)],
            [formatMessage({ id: 'Vacancy (%)' }), formatPercent(revenues.vacancy)],
            [formatMessage({ id: 'Projected net revenue' }), formatMoney(projectedNetRevenue)],
            [formatMessage({ id: 'Difference with target revenue' }), {
              text: (revenueDifferenceWithTarget > 0 ? '+' : '') + formatMoney(revenueDifferenceWithTarget),
              color: revenueDifferenceWithTarget > 0 ? 'green' : 'red'
            }]
          ]
        }
      }
    ];
  };

  const summarySection = (data: CommercialConstructionCalculatorData): Content => {
    const { totalRevenues } = getCalculatedData(data);


    const purchasePrice = data.calculatorValues.totalEstimate;
    const mortgage = data.mortgageCalculator.scenarios[data.revenues.selectedMortgageScenario];
    const netRevenue = totalRevenues - _.sumBy(data.operatingCosts, p => p.annualCost);

    return [
      { text: formatMessage({ id: 'Summary' }), style: 'header', pageBreak: 'before', pageOrientation: 'portrait' },
      {
        table: {
          widths: ['*', '*'],
          body: [
            [formatMessage({ id: 'Total revenues' }), formatMoney(totalRevenues)],
            [formatMessage({ id: 'Operating costs' }), formatMoney(_.sumBy(data.operatingCosts, p => p.annualCost))],
            [formatMessage({ id: 'Effective net revenue' }), formatMoney(netRevenue)],
            [formatMessage({ id: 'Loan' }), `${formatMoney(mortgage?.loanAmount)}  @ ${formatPercent(mortgage?.annualInterestRate)}`],
            [formatMessage({ id: 'Down payment' }), `${formatMoney(mortgage?.downPaymentPercent * purchasePrice)}`],
            [formatMessage({ id: 'Mortgage annual payment' }), formatMoney(mortgage?.annualPayment)]
          ]
        }
      }
    ];
  };

  const createDefinition = (data: CommercialConstructionCalculatorData): TDocumentDefinitions => {
    const content: Content[] = [
      {
        text: formatMessage({ id: data.name }),
        bold: true,
        alignment: 'center',
        fontSize: 20
      },
      estimatesSection(data.calculatorValues),
      mortgageSection(data),
      costsSection(data),
      revenuesSection(data),
      summarySection(data)
    ];

    return {
      content,
      defaultStyle: {
        font: 'Roboto'
      },
      styles,
      header: (currentPage) => {
        if (currentPage === 1) return;
        return {
          text: data.name,
          alignment: 'center',
          marginTop: 20
        };
      },
      footer: (currentPage) => {
        return {
          text: currentPage.toString(),
          alignment: 'center'
        };
      }
    };
  };

  const generatePDF = (data: CommercialConstructionCalculatorData) => {
    const definition = createDefinition(data);

    pdfMake.createPdf(definition, undefined, fonts).open();
  };

  return { generatePDF };
};

